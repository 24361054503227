/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react' // Added useState import
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Organization } from '../../reducers/organizationReducer'
import { PlayerState } from '../../reducers/playersReducer'
import { RedirectToPortal } from '../Stripe/utils'
import { useAxiosWithHeader } from '../../utils'
import CancelSubscriptionModal from './cancelModal'

export default function MyCurrentBilling() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const players = useSelector(PlayerState.selectors.getPlayers)
  const axios = useAxiosWithHeader()
  const activeEngineers = players.filter(
    (player) => player.communicationToolId?.length > 0,
  )
  const history = useHistory()
  const isStarterWithPreviousPayment =
    organization.plan === 'starter' && organization.payments.length > 0

  // Added state for billingText and paymentText
  const [billingText, setBillingText] = useState('')
  const [paymentText, setPaymentText] = useState('')

  const fetchStripeSubscription = async () => {
    try {
      const response = await axios.get(`subscription/${organization.id}`)
      return response.data
    } catch (error) {
      console.error('Error fetching subscription:', error)
      return null
    }
  }

  useEffect(() => {
    const getSubscriptionDetails = async () => {
      const subscription = await fetchStripeSubscription()
      if (subscription) {
        const { plan, price, nextPayment, quantity, product } = subscription
        const isMonthlySubscription = plan.interval === 'month'
        const totalPrice = price * quantity

        // Update billing and payment texts using setBillingText and setPaymentText
        setBillingText(
          `With ${quantity} onboarded engineers on the ${product.name} plan, your ${
            isMonthlySubscription ? 'monthly' : 'yearly'
          } subscription is $${totalPrice}.`,
        )
        setPaymentText(
          `Next payment expected on ${moment(nextPayment).format('MMMM Do YYYY')}.`,
        )
      } else {
        // Handle case where subscription is not found
        handleFallbackText()
      }
    }

    getSubscriptionDetails()
  }, [organization.id]) // Added dependencies

  const handleFallbackText = () => {
    const { plan, payments } = organization
    const lastPayment = payments?.[0]
    const discount = lastPayment?.data?.subscriptionResp?.discount
    let discountText = ''
    let reduction = 0

    if (discount) {
      reduction = discount.coupon?.percent_off
      discountText = `You are currently receiving a ${reduction}% discount.`
    }

    const isMonthlySubscription = lastPayment?.subscriptionType === 'monthly'
    let price = 0
    let planName = 'Free'

    if (plan === 'professional') {
      price = (isMonthlySubscription ? 10 : 100) * (1 - reduction / 100)
      planName = 'Standard'
    } else if (plan === 'business') {
      price = (isMonthlySubscription ? 16 : 160) * (1 - reduction / 100)
      planName = 'Business'
    }

    // Update billingText and paymentText based on lastPayment
    if (lastPayment) {
      setBillingText(
        `With ${
          activeEngineers.length
        } onboarded engineers on the ${planName} plan, your ${
          isMonthlySubscription ? 'monthly' : 'yearly'
        } subscription is $${activeEngineers.length * price}.`,
      )
      setPaymentText(
        `Next payment expected on ${moment(lastPayment?.subscriptionEnd).format(
          'MMMM Do YYYY',
        )}. ${discountText}`,
      )
    } else {
      // Handle case where there is no last payment
      setBillingText(`No active subscription found.`)
      setPaymentText(`Please check your billing information.`)
    }

    const isStripeStopped =
      organization.stripeStatus === 'deleted' || organization.stripeStatus === 'cancelled'
    if (isStripeStopped) {
      setBillingText(`Your subscription has been canceled.`)
      setPaymentText(
        `It will be running until ${moment(lastPayment?.subscriptionEnd).format(
          'MMMM Do YYYY',
        )}. ${discountText}`,
      )
    }

    if (isStarterWithPreviousPayment) {
      setBillingText(`You've been downgraded to the free plan.`)
      setPaymentText(
        `If you wish to pursue your Axolo subscription, please click on 'Update my billing information' below.`,
      )
    }
  }

  // Calculate price and isMonthlySubscription for rendering
  const lastPayment = organization.payments?.[0]
  const isMonthlySubscription = lastPayment?.subscriptionType === 'monthly'
  let price = 0
  let planName = 'Free'

  if (organization.plan === 'professional') {
    price = isMonthlySubscription ? 10 : 100
    planName = 'Standard'
  } else if (organization.plan === 'business') {
    price = isMonthlySubscription ? 16 : 160
    planName = 'Business'
  }

  const totalPrice = activeEngineers.length * price

  return (
    <div className="">
      <h1 className=" text-xl font-semibold text-gray-900">Billing</h1>
      <div className="mx-10 mb-20 mt-14 flex justify-center font-sans">
        <div className="grid grid-cols-8 gap-4 rounded-lg border border-gray-200  shadow-lg">
          <div className="col-span-1 self-center justify-self-center py-4 pl-8 pr-4 text-2xl ">
            <span className="font-bold">${totalPrice}</span>/
            {isMonthlySubscription ? 'mo' : 'yr'}
          </div>
          <div className="col-span-7 border-l  border-gray-200 p-4">
            <div className="grid grid-cols-1">
              <span>
                {billingText}
                <br />
                {paymentText}
              </span>
              <div>
                <button
                  onClick={() => RedirectToPortal({ axios })}
                  className="mt-2 w-fit rounded bg-primary px-4 py-2  text-white hover:bg-hoverPrimary"
                >
                  Find my invoices
                </button>
                {isStarterWithPreviousPayment ? (
                  <button
                    disabled
                    className="ml-2 mt-2 w-fit cursor-not-allowed rounded bg-gray-400 px-4 py-2 text-white"
                  >
                    To resubscribe, click on the Upgrade button below
                  </button>
                ) : (
                  <button
                    onClick={() => RedirectToPortal({ axios })}
                    className="ml-2 mt-2 w-fit rounded bg-primary px-4 py-2  text-white hover:bg-hoverPrimary"
                  >
                    Update my billing information or Switch plan to yearly
                  </button>
                )}

                <button
                  className="ml-2 mt-2 w-fit rounded bg-primary px-4 py-2  text-white hover:bg-hoverPrimary"
                  onClick={() => history.push('/team/team-management')}
                >
                  Manage my team
                </button>
                {!(
                  organization.stripeStatus === 'deleted' ||
                  organization.stripeStatus === 'cancelled'
                ) && <CancelSubscriptionModal />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
